import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Featured from './Featured';

function App() {
  return (
      <div className="App">

      <Featured></Featured>
      
      </div>
  );
}

export default App;